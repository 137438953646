'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Grid$Paulshen = require("../components/Grid.bs.js");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Layer$Paulshen = require("../components/Layer.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");
var PhotoOverlay$Paulshen = require("../ui/PhotoOverlay.bs.js");
var ExternalPhoto$Paulshen = require("../components/ExternalPhoto.bs.js");
var WindowDimensions$Paulshen = require("../components/WindowDimensions.bs.js");

var topSection = Css.style(/* :: */[
      Css.maxWidth(Css.px(896)),
      /* :: */[
        Css.margin3(Css.zero, Css.auto, Css.px(128)),
        /* :: */[
          MediaQuery$Paulshen._840(/* :: */[
                Css.marginBottom(Css.px(64)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var text = Css.merge(/* :: */[
      Text$Paulshen.Styles.body,
      /* :: */[
        Css.style(/* :: */[
              Css.maxWidth(Css.px(560)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var header = Css.style(/* :: */[
      Css.marginBottom(Css.px(48)),
      /* [] */0
    ]);

var cell = Css.style(/* :: */[
      Css.marginBottom(Css.px(24)),
      /* [] */0
    ]);

var overlay = Css.style(/* :: */[
      Css.backgroundColor(Colors$Paulshen.primary500),
      /* [] */0
    ]);

var Styles = {
  topSection: topSection,
  text: text,
  header: header,
  cell: cell,
  overlay: overlay
};

function DrawingsPage$DrawingImage(Props) {
  var drawing = Props.drawing;
  var data = Props.data;
  var setSelectedImage = Props.setSelectedImage;
  return React.createElement("div", {
              onClick: (function (param) {
                  return Curry._1(setSelectedImage, (function (param) {
                                return data.dataJson.images.directory + drawing.src;
                              }));
                })
            }, React.createElement(ExternalPhoto$Paulshen.make, {
                  data: data.dataJson.thumbnails,
                  url: /* Local */Block.__(1, [data.dataJson.images.directory + drawing.src]),
                  minAspectRatio: 0.8,
                  maxAspectRatio: 1.4
                }));
}

var DrawingImage = {
  make: DrawingsPage$DrawingImage
};

function getLayoutFromDrawings(drawings) {
  var currentLayout = {
    side: /* Left */0,
    drawings: /* array */[]
  };
  var currentLayoutDereferenced = currentLayout;
  var layouts = /* array */[currentLayoutDereferenced];
  for(var i = 0 ,i_finish = drawings.length - 1 | 0; i <= i_finish; ++i){
    var drawing = Caml_array.caml_array_get(drawings, i);
    var match = currentLayout;
    var drawings$1 = match.drawings;
    if (drawings$1.length === 5) {
      currentLayout = {
        side: match.side ? /* Left */0 : /* Right */1,
        drawings: /* array */[drawing]
      };
      layouts.push(currentLayout);
    } else {
      drawings$1.push(drawing);
    }
  }
  return layouts;
}

function renderDrawingList(drawings, data, setSelectedImage) {
  return React.createElement(Grid$Paulshen.Cell.make, {
              span: 6,
              className: cell,
              children: React.createElement(Grid$Paulshen.Root.make, {
                    children: drawings.map((function (drawing, i) {
                            return React.createElement(Grid$Paulshen.Cell.make, {
                                        span: 3,
                                        children: React.createElement(DrawingsPage$DrawingImage, {
                                              drawing: drawing,
                                              data: data,
                                              setSelectedImage: setSelectedImage
                                            }),
                                        key: String(i)
                                      });
                          }))
                  })
            });
}

function renderBody(windowWidth, data, setSelectedImage) {
  if (windowWidth > 840) {
    return getLayoutFromDrawings(data.dataJson.images.items).map((function (layout, i) {
                  var drawings = layout.drawings;
                  var single = React.createElement(Grid$Paulshen.Cell.make, {
                        span: 6,
                        className: cell,
                        children: React.createElement(DrawingsPage$DrawingImage, {
                              drawing: Caml_array.caml_array_get(drawings, 0),
                              data: data,
                              setSelectedImage: setSelectedImage
                            })
                      });
                  var grid = renderDrawingList(drawings.slice(1), data, setSelectedImage);
                  if (layout.side) {
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: String(i)
                              }, grid, single);
                  } else {
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: String(i)
                              }, single, grid);
                  }
                }));
  } else {
    return data.dataJson.images.items.map((function (drawing, i) {
                  return React.createElement(Grid$Paulshen.Cell.make, {
                              span: 6,
                              children: React.createElement(DrawingsPage$DrawingImage, {
                                    drawing: drawing,
                                    data: data,
                                    setSelectedImage: setSelectedImage
                                  }),
                              key: String(i)
                            });
                }));
  }
}

function DrawingsPage(Props) {
  var data = Props.data;
  var windowWidth = React.useContext(WindowDimensions$Paulshen.context);
  var match = React.useState((function () {
          return ;
        }));
  var setSelectedImage = match[1];
  var selectedImage = match[0];
  var tmp;
  if (selectedImage !== undefined) {
    var selectedImage$1 = selectedImage;
    tmp = React.createElement(Layer$Paulshen.make, {
          children: React.createElement(PhotoOverlay$Paulshen.make, {
                thumbnails: data.dataJson.thumbnails,
                images: data.dataJson.full,
                startingIndex: data.dataJson.full.findIndex((function (file) {
                        return file.relativePath === selectedImage$1;
                      })),
                onClose: (function (param) {
                    return Curry._1(setSelectedImage, (function (param) {
                                  return ;
                                }));
                  }),
                className: overlay
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement(Page$Paulshen.make, {
              children: null,
              title: "Draw"
            }, React.createElement(Grid$Paulshen.Root.make, {
                  className: topSection,
                  children: null
                }, React.createElement(Grid$Paulshen.Cell.make, {
                      span: 4,
                      children: React.createElement(Text$Paulshen.Header.make, {
                            children: "Drawings",
                            size: /* S3 */18560,
                            tag: /* H1 */16105
                          }),
                      fullWidthOnMobile: true
                    }), React.createElement(Grid$Paulshen.Cell.make, {
                      span: 8,
                      className: text,
                      children: null,
                      fullWidthOnMobile: true
                    }, React.createElement("p", undefined, "Drawing on location is a form of slow, interpretative photography. The lens is imperfect and lossy but injects a reasonable dose of personality."), React.createElement("p", undefined, "It requires a different perspective, drawing what you see. Instead of seeing nouns such as a building or a face, I look for primitives, lines and angles. I'm learning to see things as they are, without distortion."), React.createElement("p", undefined, "The following sketches were done in Procreate on iPad."))), React.createElement(Grid$Paulshen.Root.make, {
                  children: renderBody(windowWidth, data, setSelectedImage)
                }), tmp);
}


const { graphql } = require('gatsby');
exports.query = graphql`
  query DrawQuery {
    dataJson(id: { eq: "drawings" }) {
      images {
        directory
        items {
          src
        }
      }
      thumbnails: imageFiles {
        relativePath
        childImageSharp {
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      full: imageFiles {
        relativePath
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  }
`

;

var make = DrawingsPage;

var slug = "/drawings";

var $$default = DrawingsPage;

exports.Styles = Styles;
exports.DrawingImage = DrawingImage;
exports.getLayoutFromDrawings = getLayoutFromDrawings;
exports.renderDrawingList = renderDrawingList;
exports.renderBody = renderBody;
exports.make = make;
exports.slug = slug;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* topSection Not a pure module */
