'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var Gatsby$Paulshen = require("../components/Gatsby.bs.js");

var strip = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* [] */0
    ]);

var image = Css.style(/* :: */[
      Css.paddingRight(Css.px(32)),
      /* :: */[
        Css.selector("img", /* :: */[
              Css.userSelect(Css.none),
              /* :: */[
                Css.unsafe("userDrag", "none"),
                /* :: */[
                  Css.userSelect(Css.none),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var Styles = {
  strip: strip,
  image: image
};

function PhotoOverlay$StaticThumbnailStrip(Props) {
  var thumbnails = Props.thumbnails;
  var imageHeight = Props.imageHeight;
  var onClick = Props.onClick;
  var className = Props.className;
  var style = Props.style;
  var onMouseDown = Props.onMouseDown;
  var onTouchStart = Props.onTouchStart;
  var domRef = Props.domRef;
  var ref = Belt_Option.map(domRef, (function (domRef) {
          return domRef;
        }));
  var tmp = {
    className: Cn.make(/* :: */[
          strip,
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ])
  };
  if (ref !== undefined) {
    tmp.ref = Caml_option.valFromOption(ref);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onMouseDown !== undefined) {
    tmp.onMouseDown = Caml_option.valFromOption(onMouseDown);
  }
  if (onTouchStart !== undefined) {
    tmp.onTouchStart = Caml_option.valFromOption(onTouchStart);
  }
  return React.createElement("div", tmp, thumbnails.map((function (thumbnail, i) {
                    return React.createElement("div", {
                                key: String(i),
                                className: image,
                                onClick: (function (param) {
                                    return Curry._1(onClick, i);
                                  })
                              }, React.createElement(Gatsby$Paulshen.$$Image.make, {
                                    data: thumbnail.childImageSharp,
                                    maxPresentationWidth: false,
                                    style: {
                                      width: (imageHeight * thumbnail.childImageSharp.fluid.aspectRatio).toString() + "px"
                                    },
                                    imgStyle: {
                                      objectFit: "contain"
                                    }
                                  }));
                  })));
}

var StaticThumbnailStrip = {
  Styles: Styles,
  make: PhotoOverlay$StaticThumbnailStrip
};

var scroller = Css.style(/* :: */[
      Css.overflow(Css.hidden),
      /* :: */[
        Css.flexShrink(0),
        /* [] */0
      ]
    ]);

var strip$1 = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.padding2(Css.px(48), Css.zero),
        /* [] */0
      ]
    ]);

var image$1 = Css.style(/* :: */[
      Css.marginRight(Css.px(32)),
      /* [] */0
    ]);

var Styles$1 = {
  scroller: scroller,
  strip: strip$1,
  image: image$1
};

function PhotoOverlay$ThumbnailStrip(Props) {
  var thumbnails = Props.thumbnails;
  var index = Props.index;
  var setIndex = Props.setIndex;
  var scrollOffset = React.useMemo((function () {
          var sum = 0;
          for(var i = 0 ,i_finish = index - 1 | 0; i <= i_finish; ++i){
            sum = sum + 96 * Caml_array.caml_array_get(thumbnails, i).childImageSharp.fluid.aspectRatio + 32;
          }
          var scrollOffsetForIndex = sum;
          return -scrollOffsetForIndex + (window.innerWidth - 96 * Caml_array.caml_array_get(thumbnails, index).childImageSharp.fluid.aspectRatio) / 2;
        }), /* array */[index]);
  var scrollOffsetRef = React.useRef(scrollOffset);
  React.useLayoutEffect((function () {
          scrollOffsetRef.current = scrollOffset;
          return ;
        }), /* array */[scrollOffset]);
  var domRef = React.useRef(null);
  var hasDraggedRef = React.useRef(false);
  var onMouseDown = React.useCallback((function (e) {
          var startPageX = e.pageX;
          var startScrollOffset = scrollOffsetRef.current;
          var element = Belt_Option.getExn(Caml_option.nullable_to_opt(domRef.current));
          var onMouseMove = function (e) {
            var diffX = e.pageX - startPageX | 0;
            element.style.setProperty("transform", "translateX(" + ((startScrollOffset + diffX).toString() + "px)"), "");
            hasDraggedRef.current = true;
            return /* () */0;
          };
          var onMouseUp = function (e) {
            var diffX = e.pageX - startPageX | 0;
            scrollOffsetRef.current = startScrollOffset + diffX;
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            return /* () */0;
          };
          hasDraggedRef.current = false;
          window.addEventListener("mousemove", onMouseMove);
          window.addEventListener("mouseup", onMouseUp);
          return /* () */0;
        }), ([]));
  var onTouchStart = React.useCallback((function (e) {
          var touch = e.touches.item(0);
          var startPageX = touch.screenX;
          var lastDiffX = {
            contents: 0
          };
          var startScrollOffset = scrollOffsetRef.current;
          var element = Belt_Option.getExn(Caml_option.nullable_to_opt(domRef.current));
          var onTouchMove = function (e) {
            e.preventDefault();
            var touch = e.touches.item(0);
            var diffX = touch.screenX - startPageX | 0;
            lastDiffX.contents = diffX;
            element.style.setProperty("transform", "translateX(" + ((startScrollOffset + diffX).toString() + "px)"), "");
            hasDraggedRef.current = true;
            return /* () */0;
          };
          var onTouchEnd = function (e) {
            e.preventDefault();
            scrollOffsetRef.current = startScrollOffset + lastDiffX.contents;
            window.removeEventListener("touchmove", onTouchMove);
            window.removeEventListener("touchend", onTouchEnd);
            return /* () */0;
          };
          hasDraggedRef.current = false;
          window.addEventListener("touchmove", onTouchMove);
          window.addEventListener("touchend", onTouchEnd);
          return /* () */0;
        }), ([]));
  return React.createElement("div", {
              className: scroller
            }, React.createElement(PhotoOverlay$StaticThumbnailStrip, {
                  thumbnails: thumbnails,
                  imageHeight: 96,
                  onClick: (function (i) {
                      if (hasDraggedRef.current) {
                        return 0;
                      } else {
                        return Curry._1(setIndex, (function (param) {
                                      return i;
                                    }));
                      }
                    }),
                  className: strip$1,
                  style: {
                    transform: "translateX(" + (scrollOffset.toString() + "px)")
                  },
                  onMouseDown: onMouseDown,
                  onTouchStart: onTouchStart,
                  domRef: domRef
                }));
}

var ThumbnailStrip = {
  imageHeight: 96,
  Styles: Styles$1,
  make: PhotoOverlay$ThumbnailStrip
};

var overlay = Css.style(/* :: */[
      Css.position(Css.fixed),
      /* :: */[
        Css.top(Css.zero),
        /* :: */[
          Css.bottom(Css.zero),
          /* :: */[
            Css.left(Css.zero),
            /* :: */[
              Css.right(Css.zero),
              /* :: */[
                Css.display(Css.flexBox),
                /* :: */[
                  Css.flexDirection(Css.column),
                  /* :: */[
                    Css.backgroundColor(Colors$Paulshen.primary100),
                    /* :: */[
                      Css.paddingTop(Css.px(32)),
                      /* :: */[
                        Css.opacity(0),
                        /* :: */[
                          Css.transition(200, undefined, undefined, "opacity"),
                          /* :: */[
                            Css.zIndex(1),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var overlayShown = Css.style(/* :: */[
      Css.opacity(1),
      /* [] */0
    ]);

var image$2 = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.overflow(Css.hidden),
        /* :: */[
          Css.position(Css.relative),
          /* :: */[
            Css.margin2(Css.zero, Css.px(16)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles$2 = {
  overlay: overlay,
  overlayShown: overlayShown,
  image: image$2
};

function PhotoOverlay(Props) {
  var thumbnails = Props.thumbnails;
  var images = Props.images;
  var startingIndex = Props.startingIndex;
  var onClose = Props.onClose;
  var className = Props.className;
  var match = React.useState((function () {
          return startingIndex;
        }));
  var setIndex = match[1];
  var index = match[0];
  var photo = Caml_array.caml_array_get(images, index);
  React.useEffect((function () {
          var numThumbnails = thumbnails.length;
          var onKeyDown = function (e) {
            var match = e.key;
            switch (match) {
              case "ArrowLeft" :
                  return Curry._1(setIndex, (function (index) {
                                return Math.max(index - 1 | 0, 0);
                              }));
              case "ArrowRight" :
                  return Curry._1(setIndex, (function (index) {
                                return Math.min(index + 1 | 0, numThumbnails - 1 | 0);
                              }));
              case "Escape" :
                  return Curry._1(onClose, /* () */0);
              default:
                return /* () */0;
            }
          };
          window.addEventListener("keydown", onKeyDown);
          return (function (param) {
                    window.removeEventListener("keydown", onKeyDown);
                    return /* () */0;
                  });
        }), ([]));
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShown = match$1[1];
  React.useEffect((function () {
          setTimeout((function (param) {
                  return Curry._1(setShown, (function (param) {
                                return true;
                              }));
                }), 1);
          return ;
        }), ([]));
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    overlay,
                    /* :: */[
                      Cn.ifTrue(overlayShown, match$1[0]),
                      /* :: */[
                        Cn.unpack(className),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: image$2,
                  onClick: (function (param) {
                      return Curry._1(onClose, /* () */0);
                    })
                }, React.createElement(Gatsby$Paulshen.$$Image.make, {
                      data: photo.childImageSharp,
                      maxPresentationWidth: false,
                      style: {
                        position: "static"
                      },
                      imgStyle: {
                        objectFit: "contain"
                      },
                      fadeIn: false
                    })), React.createElement(PhotoOverlay$ThumbnailStrip, {
                  thumbnails: thumbnails,
                  index: index,
                  setIndex: setIndex
                }));
}

var make = PhotoOverlay;

exports.StaticThumbnailStrip = StaticThumbnailStrip;
exports.ThumbnailStrip = ThumbnailStrip;
exports.Styles = Styles$2;
exports.make = make;
/* strip Not a pure module */
