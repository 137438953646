'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Gatsby$Paulshen = require("./Gatsby.bs.js");

function ExternalPhoto(Props) {
  var data = Props.data;
  var url = Props.url;
  var maxPresentationWidth = Props.maxPresentationWidth;
  var minAspectRatio = Props.minAspectRatio;
  var maxAspectRatio = Props.maxAspectRatio;
  var style = Props.style;
  var imgStyle = Props.imgStyle;
  var className = Props.className;
  var match = data.find((function (file) {
          if (url.tag) {
            return file.relativePath === url[0];
          } else {
            return file.url === url[0];
          }
        }));
  if (match !== undefined) {
    var tmp = {
      data: match.childImageSharp
    };
    if (minAspectRatio !== undefined) {
      tmp.minAspectRatio = Caml_option.valFromOption(minAspectRatio);
    }
    if (maxAspectRatio !== undefined) {
      tmp.maxAspectRatio = Caml_option.valFromOption(maxAspectRatio);
    }
    if (maxPresentationWidth !== undefined) {
      tmp.maxPresentationWidth = Caml_option.valFromOption(maxPresentationWidth);
    }
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    if (imgStyle !== undefined) {
      tmp.imgStyle = Caml_option.valFromOption(imgStyle);
    }
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    return React.createElement(Gatsby$Paulshen.$$Image.make, tmp);
  } else {
    return null;
  }
}

var make = ExternalPhoto;

exports.make = make;
/* react Not a pure module */
