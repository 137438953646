'use strict';

var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var GatsbyImage = require("gatsby-image");

var Link = { };

var Img = { };

function Gatsby$Image(Props) {
  var data = Props.data;
  var minAspectRatio = Props.minAspectRatio;
  var maxAspectRatio = Props.maxAspectRatio;
  var match = Props.maxPresentationWidth;
  var maxPresentationWidth = match !== undefined ? match : true;
  var style = Props.style;
  var imgStyle = Props.imgStyle;
  var fadeIn = Props.fadeIn;
  var className = Props.className;
  var style$1;
  if (maxPresentationWidth) {
    var maxWidthStyle = {
      maxWidth: String(data.fluid.presentationWidth) + "px"
    };
    style$1 = Caml_option.some(style !== undefined ? ReactDOMRe.Style.combine(Caml_option.valFromOption(style), maxWidthStyle) : maxWidthStyle);
  } else {
    style$1 = style;
  }
  var fluid;
  if (minAspectRatio !== undefined) {
    var minAspectRatio$1 = minAspectRatio;
    fluid = data.fluid.aspectRatio < minAspectRatio$1 ? Object.assign(Object.assign({ }, data.fluid), {
            aspectRatio: minAspectRatio$1
          }) : data.fluid;
  } else {
    fluid = data.fluid;
  }
  var fluid$1;
  if (maxAspectRatio !== undefined) {
    var maxAspectRatio$1 = maxAspectRatio;
    fluid$1 = fluid.aspectRatio > maxAspectRatio$1 ? Object.assign(Object.assign({ }, fluid), {
            aspectRatio: maxAspectRatio$1
          }) : fluid;
  } else {
    fluid$1 = fluid;
  }
  var tmp = {
    fluid: fluid$1
  };
  if (style$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(style$1);
  }
  if (imgStyle !== undefined) {
    tmp.imgStyle = Caml_option.valFromOption(imgStyle);
  }
  if (fadeIn !== undefined) {
    tmp.fadeIn = Caml_option.valFromOption(fadeIn);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(GatsbyImage.default, tmp);
}

var $$Image = {
  make: Gatsby$Image
};

exports.Link = Link;
exports.Img = Img;
exports.$$Image = $$Image;
/* react Not a pure module */
