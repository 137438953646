'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_MapInt = require("bs-platform/lib/js/belt_MapInt.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");

var span1Percent = 100 / 12;

var root = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* :: */[
          Css.marginLeft(Css.px(-16)),
          /* :: */[
            Css.marginRight(Css.px(-16)),
            /* :: */[
              MediaQuery$Paulshen._840(/* :: */[
                    Css.marginLeft(Css.px(-12)),
                    /* :: */[
                      Css.marginRight(Css.px(-12)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var cell = Css.style(/* :: */[
      Css.boxSizing(/* borderBox */9307263),
      /* :: */[
        Css.paddingLeft(Css.px(16)),
        /* :: */[
          Css.paddingRight(Css.px(16)),
          /* :: */[
            MediaQuery$Paulshen._840(/* :: */[
                  Css.paddingLeft(Css.px(12)),
                  /* :: */[
                    Css.paddingRight(Css.px(12)),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var cellStart = Css.style(/* :: */[
      Css.paddingLeft(Css.zero),
      /* [] */0
    ]);

var cellEnd = Css.style(/* :: */[
      Css.paddingRight(Css.zero),
      /* [] */0
    ]);

var span = {
  contents: Belt_MapInt.empty
};

for(var i = 1; i <= 12; ++i){
  span.contents = Belt_MapInt.set(span.contents, i, Css.style(/* :: */[
            Css.boxSizing(/* borderBox */9307263),
            /* :: */[
              Css.width(/* `percent */[
                    -119887163,
                    span1Percent * i
                  ]),
              /* [] */0
            ]
          ]));
}

var fullWidthOnMobile = Css.style(/* :: */[
      MediaQuery$Paulshen._840(/* :: */[
            Css.important(Css.width(Css.pct(100))),
            /* [] */0
          ]),
      /* [] */0
    ]);

var Styles = {
  span1Percent: span1Percent,
  root: root,
  cell: cell,
  cellStart: cellStart,
  cellEnd: cellEnd,
  span: span,
  fullWidthOnMobile: fullWidthOnMobile
};

var context = React.createContext(12);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

function Grid$Root(Props) {
  var className = Props.className;
  var span = Props.span;
  var children = Props.children;
  var body = React.createElement("div", {
        className: Cn.make(/* :: */[
              root,
              /* :: */[
                Cn.unpack(className),
                /* [] */0
              ]
            ])
      }, children);
  if (span !== undefined) {
    return React.createElement(make, makeProps(span, body, /* () */0));
  } else {
    return body;
  }
}

var Root = {
  make: Grid$Root
};

function Grid$Cell(Props) {
  var span$1 = Props.span;
  var className = Props.className;
  var match = Props.children;
  var children = match !== undefined ? Caml_option.valFromOption(match) : null;
  var match$1 = Props.fullWidthOnMobile;
  var fullWidthOnMobile$1 = match$1 !== undefined ? match$1 : false;
  var contextSpan = React.useContext(context);
  var match$2 = Caml_int32.mod_(12, contextSpan) === 0 ? /* tuple */[
      Belt_MapInt.get(span.contents, Caml_int32.imul(span$1, Caml_int32.div(12, contextSpan))),
      undefined
    ] : /* tuple */[
      undefined,
      {
        width: (span$1 / contextSpan * 100).toString() + "%"
      }
    ];
  var tmp = {
    className: Cn.make(/* :: */[
          cell,
          /* :: */[
            Cn.unpack(match$2[0]),
            /* :: */[
              Cn.unpack(className),
              /* :: */[
                Cn.ifTrue(fullWidthOnMobile, fullWidthOnMobile$1),
                /* [] */0
              ]
            ]
          ]
        ])
  };
  var tmp$1 = match$2[1];
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(make, makeProps(span$1, React.createElement("div", tmp, children), /* () */0));
}

var Cell = {
  make: Grid$Cell
};

exports.Styles = Styles;
exports.context = context;
exports.ContextProvider = ContextProvider;
exports.Root = Root;
exports.Cell = Cell;
/* root Not a pure module */
